<div class="t-content" id="rent-a-car" style="padding-bottom: 20px;">
  <form (ngSubmit)="_onSubmit(_form, $event)" [formGroup]="_form" novalidate>
    <div class="form-row">
      <div class="form-group col-md-12 col-sm-12 col-lg-4 align-items-center scroll" id="form-origin">
        <label for="origin">&nbsp;</label>
        <input
          matInput
          placeholder="Lugar Recogida"
          style="background-color: white"
          maxlength="20"
          name="origin"
          id="origin"
          class="form-control input-search-box"
          autofocus
          #origin
          formControlName="origin"
          [matAutocomplete]="auto"
        />
        <mat-autocomplete
          class="mt-2"
          #auto="matAutocomplete"
          [displayWith]="displayFn"
          (optionSelected)="updateMySelectionOrigin($event.option.value)"
        >
          <mat-option *ngFor="let name of originOptions | async" [value]="name">
            <div class="row" style="padding-top: 13px">
              <div class="col-2">
                <img class="imag-redo" [src]="name.pic_mini" />
              </div>
              <div class="col-10" style="line-height: 19px; padding-left: 25px">
                <span>
                  <span style="color: #00cc99">{{ capitalize(name.city_name) }} </span>
                  <!-- , Fl, Estados Unidos -->
                </span>
                <span class="ml-1"> ({{ name.city_iata_code }})</span> <br />
                <i class="fa fa-map-marker" aria-hidden="true"></i>
                <span style="font-size: 13px !important; padding-left: 5px !important"> Ciudad </span>
              </div>
            </div>
          </mat-option>
          <mat-option *ngFor="let name of originOptions_ | async" [value]="name">
            <div class="row" style="padding-top: 13px">
              <div class="col-2">
                <img class="imag-redo" [src]="name.pic_mini" />
              </div>
              <div class="col-10" style="line-height: 19px; padding-left: 25px">
                <span>
                  <span style="color: #00cc99">{{ capitalize(name.nameAirport) }} </span>
                  <!-- , Fl, Estados Unidos  -->
                </span>
                <span class="ml-1"> ({{ name.codeIataAirport }})</span> <br />
                <img height="12" src="assets/img/app/little-icons/airplane-dark.svg" />
                <span style="font-size: 13px !important; padding-left: 5px !important"> Aeropuerto </span>
              </div>
            </div>
          </mat-option>
        </mat-autocomplete>
        <span class="help-block text-danger" *ngIf="_form.get('origin').touched && _form.get('origin').hasError('required')"
          >This field is required.</span
        >
        <span class="help-block text-danger">&nbsp;</span>

        <span class="help-block text-danger" *ngIf="_form.get('origin').touched && _form.get('origin').hasError('invalidcountry')"
          >Invalido Formato para el lugar de recogida.</span
        >
        <span class="help-block text-danger" *ngIf="_form.get('origin').touched && _form.get('origin').hasError('invalidDestination')"
          >Invalido Formato para el lugar de recogida sleccione uno de la lista</span
        >
      </div>
      <div class="form-group col-md-12 col-sm-12 col-lg-2">
        <label for="durations">&nbsp;</label>
        <div class="form-group">
          <input
            type="hidden"
            id="datePicker"
            #datePicker
            formControlName="dateStarEnd"
            autocomplete="off"
            (change)="change($event)"
            class="form-control input-search-box"
            ngxDaterangepickerMd
            [locale]="{ format: 'DD/MM/YYYY', displayFormat: 'DD/MM/YYYY' }"
            startKey="start"
            endKey="end"
            [(ngModel)]="dateRange"
            [showRangeLabelOnInput]="false"
            [linkedCalendars]="true"
            [closeOnAutoApply]="true"
            [minDate]="minDate"
            [maxDate]="maxDate"
            [autoApply]="true"
            (startDateChanged)="startDateClicked($event)"
            (endDateChanged)="endDateClicked($event)"
          />
          <input
            formControlName="dateStar"
            placeholder="Fecha retiro"
            class="form-control input-search-box ngx-daterangepicker-action"
            #dateStart
            id="dateStart"
            readonly
            type="text"
            (click)="openDatepicker('first')"
          />
        </div>
      </div>
      <div class="form-group col-xg-3 col-lg-1 col-md-4 col-sm-6">
        <label for="durations">&nbsp;</label>
        <select
          class="form-control input-search-box"
          formControlName="hourOrigin"
          [(ngModel)]="ngSelectHourOrigin"
          style="padding: 0px 0px !important;
          font-weight: 500;
          color: #868e9d !important;
          font-size: 15px;
          font-family: 'Poppins"
        >
          <!-- <option  value="" disabled selected>H.Retiro </option>-->
          <option *ngFor="let hour of times; index as i" [value]="hour" style="padding: 0px 0px !important">
            {{ hour }}
          </option>
        </select>
      </div>

      <div class="form-group col-md-12 col-sm-12 col-lg-2">
        <label for="durations">&nbsp;</label>
        <div class="form-group">
          <input
            type="hidden"
            readonly
            [lockStartDate]="true"
            formControlName="dateStarEnd"
            autocomplete="off"
            (change)="change($event)"
            class="form-control input-search-box"
            ngxDaterangepickerMd
            [locale]="{ format: 'DD/MM/YYYY', displayFormat: 'DD/MM/YYYY' }"
            startKey="start"
            endKey="end"
            [(ngModel)]="dateRange"
            [showRangeLabelOnInput]="false"
            [linkedCalendars]="true"
            [closeOnAutoApply]="true"
            [minDate]="minDate"
            [maxDate]="maxDate"
            [autoApply]="true"
            (startDateChanged)="startDateClicked($event)"
            (endDateChanged)="endDateClicked($event)"
            id="datePicker_"
            #datePicker_
          />
          <input
            formControlName="dateEnd"
            placeholder="Fecha destino"
            class="form-control input-search-box ngx-daterangepicker-action"
            type="text"
            #dateEnd
            id="dateEnd"
            readonly
            (click)="openDatepicker('last')"
          />
        </div>
      </div>

      <div class="form-group col-xg-3 col-lg-1 col-md-4 col-sm-6">
        <label for="durations">&nbsp;</label>
        <select
          class="form-control input-search-box"
          formControlName="hourDestination"
          [(ngModel)]="ngSelectHourDestination"
          style="padding: 0px 0px !important; font-weight: 500; color: #868e9d !important; font-size: 15px; font-family: 'Poppins'"
        >
          <!-- <option  value="" disabled selected>H.Devolución </option> -->
          <option *ngFor="let hour of times; index as i" [value]="hour" style="padding: 0px 0px !important">
            {{ hour }}
          </option>
        </select>
      </div>
      <div class="form-group col-xs-12 col-lg-2 col-md-4 justify-content-start align-middle btns">
        <button
          class="btn btn-search-car rounded-pill d-flex justify-content-center align-items-center"
          type="submit"
          [ngStyle]="{ 'background-color': getClientsSettingAppBooking?.clients_setting_global?.color_main_1 }"
          [disabled]="!_form.valid || _findData || userService.itineraryActive.length == 0 || _form.get('age').value < 25"
        >
          <i class="{{ _findData ? 'fa fa-spinner fa-spin fa-sm' : '' }}"></i>
          Buscar
        </button>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-xg-4 col-lg-4 col-xs-0 col-md-1 justify-content-start align-middle py-2">
        <div class="checkbox">
          <label>
            <input
              formControlName="returnDestinations"
              type="checkbox"
              (click)="changueReturnDestinations()"
              [checked]="!returnDestinations"
            />
            <span class="cr">
              <i class="cr-icon fas fa-check"></i>
            </span>
            <label class="form-check-label">Retornar en otra Ubicación</label>
          </label>
        </div>
      </div>

      <div class="form-group col-md-12 col-sm-12 col-lg-4 align-items-center scroll" id="form-destination">
        <ng-container *ngIf="!returnDestinations">
          <input
            matInput
            placeholder="Lugar de Retorno"
            style="background-color: white"
            maxlength="20"
            id="destination"
            name="destination"
            class="form-control input-search-box"
            autofocus
            #destination
            formControlName="destination"
            [matAutocomplete]="auto"
          />
          <mat-autocomplete
            class="mt-2"
            #auto="matAutocomplete"
            [displayWith]="displayFn"
            (optionSelected)="updateMySelectionDestination($event.option.value)"
          >
            <mat-option *ngFor="let name of destinationOptions | async" [value]="name">
              <div class="row" style="padding-top: 13px">
                <div class="col-2">
                  <img class="imag-redo" [src]="name.pic_mini" />
                </div>
                <div class="col-10" style="line-height: 19px; padding-left: 25px">
                  <span>
                    <span style="color: #00cc99">{{ capitalize(name.city_name) }} </span></span
                  >
                  <!-- , Fl, Estados Unidos -->
                  <span class="ml-1"> ({{ name.country_iata_code }})</span> <br />
                  <i class="fa fa-map-marker" aria-hidden="true"></i>
                  <span style="font-size: 13px !important; padding-left: 5px !important"> Ciudad </span>
                </div>
              </div>
            </mat-option>
            <mat-option *ngFor="let name of destinationOptions_ | async" [value]="name">
              <div class="row" style="padding-top: 13px">
                <div class="col-2">
                  <img class="imag-redo" [src]="name.pic_mini" />
                </div>
                <div class="col-10" style="line-height: 19px; padding-left: 25px">
                  <span>
                    <span style="color: #00cc99">{{ capitalize(name.nameAirport) }} </span> , Fl, Estados Unidos
                  </span>
                  <span class="ml-1"> ({{ name.codeIataAirport }})</span> <br />
                  <img height="12" src="assets/img/app/little-icons/airplane-dark.svg" />
                  <span style="font-size: 13px !important; padding-left: 5px !important"> Aeropuerto </span>
                </div>
              </div>
            </mat-option>
          </mat-autocomplete>
          <span class="help-block text-danger" *ngIf="_form.get('destination').touched && _form.get('destination').hasError('required')"
            >This field is required.</span
          >
          <span class="help-block text-danger">&nbsp;</span>

          <span
            class="help-block text-danger"
            *ngIf="_form.get('destination').touched && _form.get('destination').hasError('invalidcountry')"
            >Invalido Formato para el lugar de devolucón.</span
          >
          <span
            class="help-block text-danger"
            *ngIf="_form.get('destination').touched && _form.get('destination').hasError('invalidDestination')"
            >Invalido Formato para el lugar de devolución sleccione uno de la lista</span
          >
        </ng-container>
      </div>

      <div class="form-group col-md-12 col-sm-12 col-lg-2" style="text-align: end">
        <label class="mt-3"> Edad del Conductor </label>
      </div>
      <div class="form-group col-md-12 col-sm-12 col-lg-2">
        <input
          ngui-datetime-picker
          formControlName="age"
          type="number"
          class="form-control input-search-box"
          id="age"
          placeholder="Edad"
          min="0"
          max="99"
          [(ngModel)]="ageDefault"
          autocomplete="off"
        />
      </div>
    </div>
  </form>
</div>
