export const setting= {
  name: "Moebius",
  production: false,
  c_domain: '.fusionpax.com',
  apiBTIUrl: 'https://auth-api.usblick.com/api/',
  appUrl: 'https://login-test.fusionpax.com',
  authApiUrl : 'https://auth-api.usblick.com/api/',
  appBookingBackoffice: 'https://booking-bo-test.fusionpax.com/',
  appBookingUrl: 'https://booking-test.fusionpax.com/',
  apiBookingUrl: 'https://booking-api-test.fusionpax.com/api/v1/',
  apiOctopusUrl: 'https://test.octopus-apis.com/api',
  apiOctopusUrlDev: 'https://dev.octopus-apis.com/api',
  routeUploadFile: 'https://auth-api.usblick.com/storage/app/public/',
  octopusUrl: 'https://test.octopus-apis.com/api',
  apiStorageBooking: 'https://booking-api-test.fusionpax.com/storage/app/public/',
  apiAbacoUrl: 'https://abaco-api-test.fusionpax.com/api/v1/',
  clientIdPaypal:"EHd6g3jaGD82NlUcn09stPzBNEgVYQaSUge13EY8wMcbnXHXS_gAkGuEATvJ9qsCjRMCffvwzFVDDEH6", 
  client_id:  'QA7kxgtk2eKrJDwK',
  client_secret: 'mL6SNux7pD8p8FoQRneYz0BP9NI9AP1519938479',
  defauldTimeBooking: 10,
  ApiUser : "TESTT000001",
  ApiKey  : "900TEST1S02B000",

  abacoApiUser: "devUser",
  abacoApiKey: "DevUs2024#?13579",

  app_names: {
  	booking_backoffice: 'backoffice',
  	zoco: 'zoco',
  	booking: 'booking',
  	abaco: 'abaco',
  	chat: 'chat',
  	octopus: 'octopus'
  },
  ActivatedGuard: true
};