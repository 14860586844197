import { Component, OnInit, EventEmitter, Output, Input, ViewContainerRef, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormArray, ValidatorFn, AbstractControl } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap';
import { RestService } from '../../../../shared/rest.service';
import { Observable } from 'rxjs/Observable';
import { TransportsService } from '../../service/transportsMockUp.service';
import * as moment from 'moment';
import { DatePipe, UpperCasePipe } from '@angular/common';
import { AddressesService } from '../../service/addresses.service';
import { UserService } from '../../../../pages/com.usblick.common/auth/user.service';
import { of } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'usb-transports',
  templateUrl: './transports.component.html',
  styleUrls: ['./transports.component.scss'],
  providers: [TransportsService, DatePipe, AddressesService, UpperCasePipe]
})
export class TransportsComponent implements OnInit {
  @Input() _close: boolean;
  @Output() origin = new EventEmitter<any>();
  @Output() modal = new EventEmitter<string>();
  @Output() payload = new EventEmitter<any>();
  @Output() results = new EventEmitter<any>();
  @Output() isLoading = new EventEmitter<boolean>();


  @ViewChild("destination") myInputField: ElementRef;
  @ViewChild('autocompleteInput') autocompleteInput!: ElementRef;

  destineValue: boolean;
  destineVale: boolean;
  originValue: boolean;
  selectediataCodeDestiny: any;
  selectediataCodeOrigin: any;
  iataCodeOrigin: any;
  nameOrigin: any;
  iataCodeDestiny: any;
  nameDestination: any;
  isLoadingResult: boolean;
  _resultsQuery: any;
  _findData: boolean;
  _form: FormGroup;
  minDate: Date;
  dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
  _maxPersons: any;
  isCollapsed = false;
  ismeridian = false;
  dest: any;
  ori: any;
  pickup: any;
  dropoff: any;
  passengers: string;
  isOpen= false;
  _actualItinerary: any;
  departureDateIn: any;
  arrivalDateIn: any;
  departureDateOut: any;
  airportIn: any;
  idFlight: any;
  itinerary: any;
  message = [];
  colorTextSearch: string;
  destinationOptions :Observable<any[]> = of([]);
  destinationOptions_: Observable<any[]> = of([]);
  currentTime: string = new Date().toISOString().slice(11, 16);
  @Input() getClientsSettingAppBooking:any;
  isHovered = false;
  isHoveredCancelDate: boolean;
  isHoverConfirm: boolean;
  mesaggeErrorVisibleOrigin: boolean = false;
  mesaggeErrorVisibleDestination: boolean = false;

  constructor(
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private _router: Router,
    private _restService: RestService,
    private _addressService: AddressesService,
    public vcr: ViewContainerRef,
    public userService:UserService
  ) {
    this._maxPersons = Array.from(Array(5).keys());
    // this.dpConfig.containerClass = 'theme-red';
    this.minDate = new Date();
    this.minDate.setDate(this.minDate.getDate() + 1);
  }


  ngOnInit() {
                this.passengers = 'Pasajeros';
                // this.getFlight();
                this.initForm();
             }

/**
 * Método para inicializar el formulario
 *
 * Este método crea un formulario reactivo en Angular con validaciones y valores por defecto.
 * También configura suscriptores para manejar cambios en los campos de origen y destino.
 *
 * @loading-> Estado del spinner
 */
async initForm() {
  this._form = this.fb.group({
    type: ['oneTrip', Validators.required],
    firstOrigin: [null, [Validators.required, this.validateAutocompleteSelectionOrigin()]],
    firstDestination: [null, [Validators.required, this.validateAutocompleteSelectionDestination()]],
    pickUp: ['',], // Validators.required
    dropOff: ['', ],//Validators.required
    firstArrivalDate: [this.minDate],
    firstTime: [this.currentTime, Validators.required],
    firstqtyAdults: [1, [Validators.required, Validators.min(1)]],
    firstqtyChilds: [0, Validators.required],
    firstchildsage: this.fb.array([]),
    secondArrivalDate: [null],
    secondTime: [null],
  });

  await this.handleChange('oneTrip');

  this._form.get('firstOrigin').valueChanges.subscribe((selectedValue) => {
      if(selectedValue) {
        this.destinationOptions = this._addressService.getLocationsAll(selectedValue);
      }
  });

  this._form.get('firstDestination').valueChanges.subscribe((selectedValue) => {
      if(selectedValue) {
        this.destinationOptions_ = this._addressService.getLocationsAll(selectedValue);
      }
  });

  this._form.get('firstOrigin').setErrors({error: true});
  this._form.get('firstDestination').setErrors({error: true});
}

/**
* Método para validar la selección en un autocomplete
*
* @returns ValidatorFn - Retorna un validador que verifica si el valor es un objeto válido.
*/
validateAutocompleteSelectionOrigin(): ValidatorFn {
  return (control: AbstractControl) => {
      if (!control.value || typeof control.value !== 'object') {
        this.mesaggeErrorVisibleOrigin = true;
        return { invalidSelection: true }; // Error si el valor no es un objeto
      }
      this.mesaggeErrorVisibleOrigin = false;
      return null; // Válido si el valor es un objeto (opción seleccionada)
  };
}

/**
* Método para validar la selección en un autocomplete
*
* @returns ValidatorFn - Retorna un validador que verifica si el valor es un objeto válido.
*/
validateAutocompleteSelectionDestination(): ValidatorFn {
  return (control: AbstractControl) => {
      if (!control.value || typeof control.value !== 'object') {
        this.mesaggeErrorVisibleDestination = true;
        return { invalidSelection: true }; // Error si el valor no es un objeto
      }
      this.mesaggeErrorVisibleDestination = false;
      return null; // Válido si el valor es un objeto (opción seleccionada)
  };
}


//   getFlight() {
//     this._itineraryService.getActiveItinerary().subscribe(
//       (data:any) => {
//         if (data) {
//           this._actualItinerary = data[0];
//           this.itinerary = this._actualItinerary['id'];
//           let flight = null;
//           if  (this.itinerary) {
//               // tslint:disable-next-line:max-line-length
//               this._itineraryService._restService.doGet('app', 'itinerary/' + this.itinerary + '/fly').subscribe(
//                 Response => {
//                     if (Response.success === false && Response.message === 'Fly not found') { // no existe vuelo
//                       console.log('flights nuLL', Response);
//                       // this.initForm();
//                     } else {
//                       flight = Response['data'][0];
//                       console.log('Has flights associated', Response);
//                       this.idFlight = flight['id'];
//                       this.departureDateIn = new Date(flight['departureIn']);
//                       this.arrivalDateIn = new Date(flight['arrivalIn']);
//                       this.departureDateOut = new Date(flight['departureOut']);
//                       this.airportIn =  flight['airportIn'];
//                       setTimeout(() => {
//                       //  this.loadinitForm();
//                       }, 100);
//                     }
//                   },
//                   Error => {
//                     console.log('Has no flights associated', Error);
//                   }
//               );
//           }
//         }
//       },
//       error => {
//         console.log(error);
//       }
//     );
//     this._itineraryService.consultActiveItinerary(this.userService._agentID);
// }

/**
 * Maneja el cambio de tipo de viaje (ida o ida y vuelta)
 *
 * @param evt - Tipo de viaje seleccionado ('oneTrip' o 'roundTrip')
 */
handleChange(evt: any) {
  console.log('event trip', evt);
  if (evt === 'roundTrip') {
     this.isOpen = true;
     this._form.get('secondArrivalDate').setValidators(Validators.compose([
       Validators.required,
       (c: FormControl) => {
         if (
           this._form !== undefined &&
           c.value !== null &&
           this._form.get('firstArrivalDate').value !== null &&
           this._form.get('firstArrivalDate').value !== undefined
         ) {
           const valid = (c.value >= this._form.get('firstArrivalDate').value) ? null : { invalidDate: true };
           this._form.controls['firstArrivalDate'].setErrors(valid);
           return valid;
         }
       }
     ]));
      this._form.get('secondTime').setValidators(Validators.required);
      this._form.get('secondArrivalDate').updateValueAndValidity();
      this._form.get('secondTime').updateValueAndValidity();
  } else {
      this.isOpen = false;
      this._form.get('secondArrivalDate').clearValidators();
      this._form.get('secondArrivalDate').updateValueAndValidity();
      this._form.get('secondTime').clearValidators();
      this._form.get('secondTime').updateValueAndValidity();
  }
}

/**
 * Verifica la validez de las fechas y horarios de los vuelos y traslados.
 *
 * @returns {boolean} `true` si los datos son válidos, `false` si hay errores.
 */
checkFlight(): boolean {
  let result = true;
  const a = this._form; // Obtiene los valores del formulario

  // Declaración de variables para las fechas y horarios de los traslados
  let firstArrivalDate: string;
  let secondArrivalDate: string | null = null; // Inicializa en `null` si es un viaje de ida
  let firstTime: string;
  let secondTime: string;
  let arrivalHourIn: string;
  let arrivalDateIn: string;
  let departureDateOut: string;
  let departureHourOut: string;
  let st: number = 0; // Inicializa `st` en 0 para evitar errores si no se asigna
  let ft: number;

  // Obtiene la fecha y hora del primer traslado
  firstArrivalDate = moment(a.value['firstArrivalDate'].toString().split(',')[0]).format('YYYY-MM-DD');
  firstTime = moment(a.value['firstTime'].toString().split(',')[0]).format('HH:mm');

  // Convierte la hora a minutos totales en el día (Ej: 14:30 → 870 minutos)
  ft = parseInt(firstTime.split(':')[0], 10) * 60 + parseInt(firstTime.split(':')[1], 10);

  // Si el tipo de viaje no es "oneTrip" (solo ida), obtiene los datos del segundo traslado
  if (a.value['type'] !== 'oneTrip') {
      secondArrivalDate = moment(a.value['secondArrivalDate'].toString().split(',')[0]).format('YYYY-MM-DD');
      secondTime = moment(a.value['secondTime'].toString().split(',')[0]).format('HH:mm');
      st = parseInt(secondTime.split(':')[0], 10) * 60 + parseInt(secondTime.split(':')[1], 10);
  }

  // Validaciones de los traslados
  if (firstArrivalDate > (secondArrivalDate || firstArrivalDate)) {
      result = false;
      this.message.push('Error, Enter a lower Pickup In Date to the Pickup OUT: ' + firstArrivalDate);
  }

  if ((ft > st) && (firstArrivalDate === secondArrivalDate)) {
      result = false;
      this.message.push('Error, Enter a lower Pickup IN time to the Pickup OUT time ' + secondTime);
  }

  // Validaciones en relación con los vuelos
  if ((this.idFlight !== undefined)) {
      // Obtiene los datos del vuelo
      arrivalDateIn = moment(this.arrivalDateIn.toString().split(',')[0]).format('YYYY-MM-DD');
      arrivalHourIn = moment(this.arrivalDateIn.toString().split(',')[0]).format('HH:mm');
      const at = parseInt(arrivalHourIn.split(':')[0], 10) * 60 + parseInt(arrivalHourIn.split(':')[1], 10);

      departureDateOut = moment(this.departureDateOut.toString().split(',')[0]).format('YYYY-MM-DD');
      departureHourOut = moment(this.departureDateOut.toString().split(',')[0]).format('HH:mm');
      const _at = parseInt(departureHourOut.split(':')[0], 10) * 60 + parseInt(departureHourOut.split(':')[1], 10);

      // Verifica si el primer traslado es antes de la llegada del vuelo
      if (firstArrivalDate > arrivalDateIn) {
          result = false;
          this.message.push('Error, Enter a lower Pickup In Date to the Flight Arrival IN: ' + arrivalDateIn);
      }

      // Ajuste de tiempo mínimo de espera (1 hora y 15 minutos) entre el traslado y el vuelo
      ft = ft + 75;
      if ((ft > at) && (firstArrivalDate === arrivalDateIn)) {
          result = false;
          this.message.push('Error, Enter a lower Pickup IN time (- 1:45hrs) to the Flight Arrival IN: ' + arrivalHourIn);
      }

      // Verifica si el segundo traslado es después de la salida del vuelo
      if (secondArrivalDate && secondArrivalDate > departureDateOut) {
          result = false;
          this.message.push('Error, Enter a lower Pickup Out Date to the Flight Departure IN: ' + departureDateOut);
      }

      // Ajuste de tiempo mínimo de espera (3 horas y 30 minutos) entre el traslado y la salida del vuelo
      st = st + 210;
      if ((st > _at) && (secondArrivalDate === departureDateOut)) {
          result = false;
          this.message.push('Error, Enter a lower Pickup OUT time (- 3:30hrs) to the Flight Departure IN: ' + departureHourOut);
      }
  }

  return result;
}


/**
 * Método para manejar el envío del formulario.
 *
 * Este método se llama cuando el formulario es enviado. Verifica si el vuelo es válido
 * y si es así, limpia el mensaje y llama al método de búsqueda.
 *
 * @param form -> FormGroup: El grupo de controles del formulario.
 * @param event -> Event (opcional): El evento que desencadena el envío del formulario.
 * @param dummy -> Object (opcional): Parámetro adicional que podría utilizarse en el futuro.
 */
_onSubmit(form: FormGroup, event?: Event, dummy?: Object) {
  this.message.splice(0, this.message.length);
  if (this.checkFlight()) {
    this.message.length = 0;
    this.goSearch(form, event, dummy); // llamar al metodo para efectuar la busqueda
  }
}

/**
 * Método para realizar la búsqueda de transportes.
 *
 * Este método verifica si las ciudades de origen y destino son válidas, y luego
 * construye un payload para enviar una solicitud POST al backend con la información
 * del formulario. Si la solicitud es exitosa, maneja la respuesta y navega a los resultados.
 *
 * @param form -> FormGroup: El grupo de controles del formulario.
 * @param event -> Event (opcional): El evento de la búsqueda.
 * @param dummy -> Object (opcional): Parámetro adicional.
 */
  goSearch(form: FormGroup, event?: Event, dummy?: Object) {
    console.log(this.selectediataCodeOrigin)
    console.log(this.selectediataCodeDestiny)
    console.log(form)
    this.originValue = ((this.selectediataCodeOrigin !== undefined) && (this.selectediataCodeOrigin !== null));
    this.destineVale = ((this.selectediataCodeDestiny !== undefined) && (this.selectediataCodeDestiny !== null));
    if ((this.originValue) && (this.destineVale)) {
      if (event) {
        event.preventDefault();
      }
      this._emitLoading(true);
      const payload = this._payload(form, this.selectediataCodeDestiny, this.selectediataCodeOrigin);
      localStorage.setItem("form", JSON.stringify(form.value));
      const payloadArray = [payload];
      localStorage.setItem("payload", JSON.stringify(payloadArray));
      console.log('payloadArray', payload);
      this._restService.doPost('app', 'search', payload).subscribe(
        (response:any) => {
          console.log('response transport', response);
          // _response = Response['status'] ? Response : this.transportsService.getTransports(payload);
          this.payload.emit(payloadArray);
          if (response !== null && response.results !== null) {
            this._colocarSearchIdEnlosHoteles(response);
          }
          this._emitOrigin('TRANSPORTS');
          this._handleResponse(response, 'TRANSPORTS', form);
          this._emitLoading(false);
          this._router.navigate(['/results']);
        },
        error => {
          this._emitLoading(false);
          //this.toastr.error('An error ocurred while trying to find products. Please try again.', 'Service Error');
          //^*********** DESCOMENTAR PARA BUSQUEDA EN SERVICIO MOCKUP***********////

          // _response = this.transportsService.getTransports(payload);
          // this.payload.emit(payloadArray);
          // if (_response !== null && _response.results !== null) {
          //   this._colocarSearchIdEnlosHoteles(_response);
          // }
          // this._emitOrigin('TRANSPORTS');
          // this._handleResponse(_response, 'TRANSPORTS', form);
          // setTimeout(() => {
          //   this._router.navigate(['/results']);
          // }, 300);
        });
    } else {
      // tslint:disable-next-line:curly
      if (!this.originValue) {
        this._form.get('firstOrigin').setErrors({ invalidDestination: true });
      }
      if (!this.destineVale) {
        this._form.get('firstDestination').setErrors({ invalidDestination: true });
      }

    }
  }

  _emitLoading(isLoading: boolean) {
    this._findData = isLoading;
    this.isLoading.emit(isLoading);
  }

  _colocarSearchIdEnlosHoteles(response: any) {
    response.results.forEach(obj => {
      obj.searchId = response.searchId;
    });
  }
  _handleResponse(response: any, categoryName: string, form: FormGroup) {
    if (response && response.results != null) {
      try {
        this._resultsQuery = response.results;
        this._resultsQuery.categoryName = categoryName;
        this._resultsQuery.arrivalDate = form.value['arrivalDate'];

        if (!response.results.length) {
          this._resultsQuery.noResultMessage = 'No results found for the search';
        }
        // se anexa el formulario actual
        this._resultsQuery.form = form;
        const arrayResults = [this._resultsQuery];
        this.results.emit(arrayResults);
      } catch (e) {
        this._resultsQuery = [];
        this._restService.page = 1;
      }
    } else {
      this._resultsQuery = [];
      this._resultsQuery.noResultMessage = 'No results found for the search';
      this.results.emit(this._resultsQuery);
    }
  }

  _payload(form: FormGroup, iataCodeDestiny: string, iataCodeOrigin: string): Object {
    console.log('form payload transfer', form);

    const payloadTransport = [];
    const payload = {
      providersId: null,
      categoriesId: [8],
      origin: iataCodeOrigin,
      destination:  iataCodeOrigin,
      priceFrom: null,
      priceTo: null,
      arrivalDate: this.getFormattedDate(form.value['firstArrivalDate'], 'date'),
      departureDate: this.getFormattedDate(form.value['secondArrivalDate'], 'date'),
      qtyProduct: 1,
      qtyPax: +form.value['firstqtyAdults'],
      providerId: null,// Esta propiedad se agrego por recomendación de octopus.
      reservationLocator:null,// Esta propiedad se agrego por recomendación de octopus.
      others: {
        maxRequestTimeOnSeconds: 20,
        minNumResults: 3,
        maxNumResultsPerAdapter: "",
        transfers: {
          transferType: 'Regular / Premium',
          pickupTime: form.value['firstTime'],
         /// dropoffTime:  this.getFormattedDate(form.value['secondTime'], 'time'),
          dropoffTime: form.value['secondTime'],
          pickupLocation: {
            id: this.pickup['id'],
            address: this.pickup['address'],
            type: this.pickup['type']
            // providerId: this.pickup['providerId'],
            // nameAdapter: this.pickup['nameAdapter']
          },
          dropoffLocation: {
            id: this.dropoff['id'],
            address: this.dropoff['address'],
            type:this.dropoff['type'],
            // providerId: this.dropoff['providerId'],
            // nameAdapter: this.dropoff['nameAdapter']
          },
          paxList: {
            adults: +form.value['firstqtyAdults'],
            children: this.getAges(form.value['firstchildsage'])
          },
          vehiclesDist: [
            {
              adults: +form.value['firstqtyAdults'],
              children: this.getAges(form.value['firstchildsage'])
            }
          ],
          oneWay: form.value['secondArrivalDate'] !== null ? false : true
        }
      }
    };
    payloadTransport.push(payload);
    console.log('pay trans HOME ----->', payloadTransport);
    return payload;
  }

  getAges(ages: Array<any>): any {
    const _ages = [];
    ages.forEach(age => {
      _ages.push(age['age']);
    });
    return _ages;
  }

  getFormattedDate(date: any, format: string): any {
    if (format === 'time') {
      const _date = date ? this.datePipe.transform(date, 'HH:mm') : '10:00';
      return _date;
    } else {
      const _date = date ? this.datePipe.transform(date, 'yyyy-MM-dd') : '2018-05-30';
      return _date;
    }
  }

  _emitOrigin(origin: any) {
    this.origin.emit(origin);
  }

  _addChild(key: string, key2: string) {
    setTimeout(() => {
      const ages: any = this._form.get(key) as FormArray;
      const qty: number = +this._form.value[key2];
      if (qty > ages.length) {
        for (let i = ages.length; i < qty; i++) {
          ages.push(this.fb.group({
            id: [i],
            age: [0, Validators.compose([Validators.required, Validators.min(0), Validators.max(17)])],
          }));
        }
      } else {
        for (let i = ages.length - 1; i >= qty; i--) {
          ages.removeAt(i);
        }
      }
    }, 10);
  }

  formArray(form: FormGroup, key: string) { return (<FormArray>form.get(key)).controls; }

  /***********************************************Auto Complete********************************************/

    capitalize(val: any) {

      if(val !== undefined) {
        return val
          .toLowerCase()
          .trim()
          .split(" ")
          .map((v) => v[0].toUpperCase() + v.substr(1))
          .join(" ");
      }
    }

/**
 * Método para obtener la descripción de la ubicación.
 *
 * Este método construye una cadena con la dirección, el código de destino, el código IATA o el código del país,
 * dependiendo de los datos disponibles en el parámetro `data`.
 *
 * @data -> objeto que contiene información de la ubicación, como `address`, `destination_code`, `iata`, `country_code`.
 * @type -> tipo de ubicación que se está buscando, aunque no se utiliza en el método.
 * @customPayload -> parámetro opcional para personalizar el comportamiento (no utilizado en este caso).
 * @returns -> una cadena que contiene la descripción de la ubicación.
 */
getDescriptionLocation(data: any, type?: string, customPayload?: boolean): string {
  if(data.address) {
    return `(${data.address})`;
  }
  else if(data.destination_code) {
    return `(${data.destination_code})`;
  }
  else if(data.iata) {
    return `(${data.iata})`;
  }
  else if(data.country_code) {
    return `(${data.country_code})`;
  }
  else {
    return '';
  }
}

/**
 * Método para obtener todas las ubicaciones según el tipo.
 *
 * Este método asigna valores a las ubicaciones de origen y destino en el formulario, basándose en el tipo
 * (origen o destino) y los datos proporcionados. Además, establece valores y valida los campos en el formulario.
 *
 * @keyword -> objeto que contiene la información de la ubicación, como `destination_code`, `id`, `address`.
 * @type -> tipo de ubicación, que puede ser 'origin' o 'destination'.
 */
getLocationsAll(keyword: any, type: string) {
  if (keyword) {
    if (type === 'origin') {
      this.selectediataCodeOrigin = this.getIataLocation(keyword);
      this.pickup = {
                      id: keyword.id,
                      address: this.getDescriptionLocation(keyword).replace(/[()]/g, ''),
                      type: keyword.type
                    };

      this._form.get('firstOrigin').setValue(keyword); // Asignamos el objeto completo
      this._form.get('firstOrigin').setErrors(null);
      this._form.get('firstOrigin').updateValueAndValidity();
    } else if (type === 'destination') {
      this.selectediataCodeDestiny = this.getIataLocation(keyword);
      this.dropoff = {
                        id: keyword.id,
                        address: this.getDescriptionLocation(keyword).replace(/[()]/g, ''),
                        type: keyword.type
                      };

      this._form.get('firstDestination').setValue(keyword); // Asignamos el objeto completo
      this._form.get('firstDestination').setErrors(null);
      this._form.get('firstDestination').updateValueAndValidity();
    }
  }
}

/**
 * Método para obtener el valor correspondiente de un objeto según el tipo.
 *
 * Este método se utiliza para devolver diferentes propiedades de un objeto `data`
 * dependiendo del valor de la propiedad `type`. Si `type` es 'airport', se devuelve el valor de `iata`.
 * Si `type` es 'hotel', se devuelve la propiedad `address`. Si `type` es 'city', se devuelve el `destination_id`.
 *
 * @param data - Objeto que contiene información del tipo de ubicación, con las propiedades `type`, `iata`, `address` y `destination_id`.
 *
 * @returns string - Valor de la propiedad correspondiente al tipo de ubicación.
 */
getIataLocation(data: any): string {
  switch(data.type) {
    case 'airport':
      return data.iata;
    case 'hotel':
      return data.address;
    case 'city':
      return data.destination_id;
  }
}
/**
 * Método para mostrar el nombre de una ubicación.
 *
 * Este método construye y devuelve una cadena con el nombre del hotel, la dirección, el código IATA, el código de destino
 * o el código de país de la ubicación. La información es extraída del objeto `name`.
 *
 * @name -> objeto que contiene la información de la ubicación, como `hotel_name`, `address`, `iata`, `destination_code`, `country_code`.
 * @returns -> una cadena con el nombre y la información adicional de la ubicación.
 */
displayFn(name): string {
  if(name) {
    return `${name.hotel_name ? name.hotel_name : name.name} ${name.address ? "("+name.address+")": ''}${name.iata ? "("+name.iata+")": ''}${name.destination_code ? "("+name.destination_code+")": ''}${name.country_code ? "("+name.country_code+")": ''}`;
  } else {
    return;
  }
}

/**
 * Método para actualizar el color del botón de confirmación.
 *
 * Este método cambia el color de fondo del botón de confirmación dependiendo de si el usuario está pasando el mouse sobre él
 * o no. El color de fondo se obtiene de la configuración global del cliente.
 */
updateButtonColorConfirm() {
  const button = document.querySelector('.btn-choice-confirm') as HTMLElement;
  if (this.isHoverConfirm) {
    button.style.backgroundColor = this.getClientsSettingAppBooking?.clients_setting_global?.color_main_1;
  } else {
    button.style.backgroundColor = 'white';
  }
}

  /***********************************************Auto Complete********************************************/

/**
 * Método para emitir un valor 'false' al modal.
 *
 * Este método se utiliza para cerrar o ocultar el modal, enviando un evento con el valor 'false'.
 */
_over() {
  this.modal.emit('false');
}

/**
 * Método para ejecutar una acción cuando se hace clic fuera de un área específica.
 *
 * Este método es llamado cuando el usuario hace clic fuera del área del modal o elemento específico.
 * Se utiliza para establecer o actualizar los pasajeros en el formulario.
 *
 * @event -> objeto que representa el evento del clic fuera.
 */
clickOutside(event: any) {
  this.setPassanger();
}

/**
 * Método para abrir el modal.
 *
 * Este método cambia el estado de la variable `isCollapsed` a `true`, y la variable `_close` a `false`.
 * Después de un pequeño retardo, emite un evento con el valor 'true' para abrir el modal.
 */
_openModal() {
  this.isCollapsed = true;
  this._close = false;
  setTimeout(() => {
    this.modal.emit('true');
  }, 100);
}

/**
 * Método para confirmar la selección y cerrar el modal.
 *
 * Este método cambia el estado de `isCollapsed` a `false` y luego ejecuta la función `setPassanger`
 * para actualizar los datos de los pasajeros.
 */
_confirm() {
  this.isCollapsed = false;
  this.setPassanger();
}

/**
 * Método para establecer los pasajeros en el formulario.
 *
 * Este método obtiene la cantidad de adultos y niños desde el formulario, y genera un string representando
 * la cantidad de pasajeros en el formato adecuado. Si no hay pasajeros seleccionados, se establece el texto "Pasajeros".
 */
setPassanger() {
  let adults = this._form.value['firstqtyAdults'];
  let childs = this._form.value['firstqtyChilds'];
  let noSelected = (parseInt(adults) === 0 && parseInt(childs) === 0);
  adults = adults != undefined ? adults + " Adultos " : null;
  childs = childs != undefined ? " - " + childs + " Niños " : null;

  this.passengers = noSelected ? "Pasajeros" : adults + childs;
}

/**
 * Método para intercambiar las ubicaciones de origen y destino.
 *
 * Este método intercambia los valores de origen y destino en el formulario. Los valores se toman de los campos
 * `firstOrigin` y `firstDestination` y luego se aplican en el formulario usando `patchValue`.
 */
swapLocations() {
  const origin = this._form.get('firstOrigin')?.value;
  const destination = this._form.get('firstDestination')?.value;

  this._form.patchValue({
    firstOrigin: destination,
    firstDestination: origin
  });
}

/**
 * Método para manejar el cambio de fecha.
 *
 * Este método se utiliza para establecer la fecha de llegada en el formulario y la hora actual,
 * o para restablecer la fecha si el valor es `null`.
 *
 * @value -> valor de tipo Date que representa la nueva fecha de llegada seleccionada.
 */
onDateChange(value: Date | null) {
  if (value) {
    this._form.get('secondArrivalDate').setValue(value);
    this._form.get('secondTime').setValue(this.currentTime);
  } else {
    this._form.get('secondArrivalDate').setValue(null);
  }
}

/**
 * Método para restablecer la fecha de llegada.
 *
 * Este método restablece el valor de `secondArrivalDate` y `secondTime` en el formulario a `null`.
 */
resetSecondArrivalDate(): void {
  this._form.get('secondArrivalDate').setValue(null);
  this._form.get('secondTime').setValue(null);
}


}
